import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { FaEnvelope, FaPhone } from 'react-icons/fa'

const FooterWrapper = styled.footer`
  background-color: var(--blue);
  color: var(--secondary);
  overflow: auto;
  p {
    font-weight: 200;
  }
`

const MaxWidth = styled.div`
  max-width: 1200px;
  margin: 1rem auto 1rem auto;
  padding: 1rem;
`

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`

const FooterText = styled.div`
  justify-self: start;
  align-self: center;
`

const FooterImage = styled.div`
  justify-self: end;
  img {
    border-radius: 10px;
    border: 5px solid var(--black);
  }
  @media (max-width: 550px) {
    justify-self: center;
  }
`

const Email = styled.a`
  .abbrev {
    display: none;
  }
  .full {
    display: inline;
  }
  @media (max-width: 400px) {
    .abbrev {
      display: inline;
    }
    .full {
      display: none;
    }
  }
`

const Footer = props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            title
            email
            phone
            devWebsite
          }
        }
        footerImage: file(relativePath: { eq: "footer_image.jpg" }) {
          childImageSharp {
            fixed(height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <FooterWrapper>
        <MaxWidth>
          <FooterGrid>
            <FooterText>
              <div>
                <p>
                  &copy; {new Date().getFullYear()}{' '}
                  {data.site.siteMetadata.title}
                </p>
              </div>
              <div style={{ margin: '2rem 0 2rem' }}>
                <p style={{ margin: '0 0 0.5rem' }}>Contact us today</p>
                <p style={{ margin: '0 0 0.5rem' }}>
                  <FaEnvelope />{' '}
                  <Email href={`mailto:${data.site.siteMetadata.email}`}>
                    <span className='abbrev'>Email</span>
                    <span className='full'>{data.site.siteMetadata.email}</span>
                  </Email>
                </p>
                <p style={{ margin: '0 0 0.5rem' }}>
                  <FaPhone />{' '}
                  <a href={`tel:+44${data.site.siteMetadata.phone.slice(1)}`}>
                    {data.site.siteMetadata.phone}
                  </a>
                </p>
              </div>
              <div>
                <p>
                  Designed by{' '}
                  <a href={data.site.siteMetadata.devWebsite}>Ben Lester</a>
                </p>
              </div>
            </FooterText>
            <FooterImage>
              <Img fixed={data.footerImage.childImageSharp.fixed} />
            </FooterImage>
          </FooterGrid>
        </MaxWidth>
      </FooterWrapper>
    )}
  />
)

export default Footer
