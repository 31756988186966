import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const RowWrapper = styled.div`
  background-color: ${props =>
    props.bgColour ? `${props.bgColour}` : 'var(--darkPrimary)'};
  color: ${props =>
    props.fontColour ? `${props.fontColour}` : 'var(--secondary)'};
  overflow: auto;
`

const Row = ({ children, bgColour, fontColour }) => {
  return (
    <RowWrapper bgColour={bgColour} fontColour={fontColour}>
      {children}
    </RowWrapper>
  )
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  bgColour: PropTypes.string,
  fontColour: PropTypes.string
}

export default Row
