import React from 'react'
import { Link } from 'gatsby'

import {
  NavWrapper,
  Nav,
  Logo,
  NavList,
  HamburgerIcon
} from '../styledComponents/header'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mobileNav: false,
      isMobile: false
    }
  }

  toggleClass = () => {
    const currentState = this.state.mobileNav
    this.setState({ mobileNav: !currentState })
  }

  componentDidMount () {
    this.updatePredicate()
    window.addEventListener('resize', this.updatePredicate)
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ mobileNav: false })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updatePredicate)
  }

  updatePredicate = () => {
    this.setState({ isMobile: window.innerWidth < 800 })
  }

  mobileClassName = () => {
    if (!this.state.isMobile) {
      return 'active'
    } else if (this.state.isMobile) {
      if (this.state.mobileNav) {
        return 'active'
      } else {
        return 'notActive'
      }
    }
  }

  render () {
    return (
      <NavWrapper>
        <Nav>
          <Logo>
            <Link to='/'>
              <h1 className='titleWide'>
                <span>Great War</span>
                <br />
                <span>Bike Tours</span>
              </h1>
            </Link>
          </Logo>
          <NavList className={this.mobileClassName()}>
            <ul>
              <li>
                <Link to='/tour-details'>Tour Details</Link>
              </li>
              <li>
                <Link to='/tour-sites'>Tour Sites</Link>
              </li>
              <li>
                <Link to='/your-guides'>Your Guides</Link>
              </li>
              <li>
                <Link to='/contact'>Contact</Link>
              </li>
            </ul>
          </NavList>
          <HamburgerIcon onClick={this.toggleClass}>
            <svg
              className={this.state.mobileNav ? 'active' : 'notActive'}
              height='32'
              viewBox='0 0 32 32'
              width='32'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M17.5 16l8.2-8.2c0.4-0.4 0.4-1 0-1.4 -0.4-0.4-1-0.4-1.4 0l-8.2 8.2L7.7 6.3c-0.4-0.4-1-0.4-1.4 0 -0.4 0.4-0.4 1 0 1.4l8.3 8.3 -8.3 8.3c-0.4 0.4-0.4 1 0 1.4 0.4 0.4 1 0.4 1.4 0l8.3-8.3 8.3 8.3c0.4 0.4 1 0.4 1.4 0 0.4-0.4 0.4-1 0-1.4L17.5 16z' />
            </svg>
            <svg
              className={this.state.mobileNav ? 'notActive' : 'active'}
              height='32'
              viewBox='0 0 32 32'
              width='32'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M4 10h24c1.1 0 2-0.9 2-2s-0.9-2-2-2H4C2.9 6 2 6.9 2 8S2.9 10 4 10zM28 14H4c-1.1 0-2 0.9-2 2s0.9 2 2 2h24c1.1 0 2-0.9 2-2S29.1 14 28 14zM28 22H4c-1.1 0-2 0.9-2 2s0.9 2 2 2h24c1.1 0 2-0.9 2-2S29.1 22 28 22z' />
            </svg>
          </HamburgerIcon>
        </Nav>
      </NavWrapper>
    )
  }
}

export default Header
