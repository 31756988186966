import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'
import './layout.css'
import 'sanitize.css/sanitize.css'

const Layout = ({ children }) => (
  <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
    <Header />
    <main style={{ flex: 1 }}>{children}</main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
