import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, image, lang, meta, keywords, title, url }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const smImage = image
    ? site.siteMetadata.url + '/images/twitter_cards/' + image
    : site.siteMetadata.url + '/images/twitter_cards/motorbike_side.jpg'

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={
        title ? `%s | ${site.siteMetadata.title}` : site.siteMetadata.title
      }
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title
        },
        {
          property: `og:locale`,
          content: 'en_GB'
        },
        {
          name: `twitter:title`,
          content: title
            ? title + ' | ' + site.siteMetadata.title
            : site.siteMetadata.title
        },
        {
          property: `og:title`,
          content: title
            ? title + ' | ' + site.siteMetadata.title
            : site.siteMetadata.title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:image`,
          content: smImage
        },
        {
          property: `og:image`,
          content: smImage
        },
        url
          ? {
            property: `og:url`,
            content: site.siteMetadata.url + url
          }
          : null
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `)
            }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
