import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FaEnvelope, FaPhone } from 'react-icons/fa'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Row from '../components/row'

import {
  MaxWidthWrapper,
  Centre,
  ImgWrapper,
  ImgContainer
} from '../styledComponents/shared'

import 'flag-icon-css/css/flag-icon.min.css'

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title='Contact Us'
        description='Have a question? Want to book on the next tour? Get in touch with us!'
        image='dover.jpg'
        url='/contact'
      />
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <MaxWidthWrapper maxWidth='500'>
          <Centre
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              textAlign: 'center'
            }}
          >
            <h1>Contact</h1>
            <p>
              To book your tour with us, to enquire about availability, or ask
              any questions you may have please contact Mark:
            </p>
            <FaEnvelope style={{ fontSize: '2rem', margin: '1rem 0 1rem 0' }} />
            <p>
              Email:{' '}
              <a href={`mailto:${data.site.siteMetadata.email}`}>
                {data.site.siteMetadata.email}
              </a>
            </p>
            <FaPhone style={{ fontSize: '2rem', margin: '1rem 0 1rem 0' }} />
            <p>
              Phone:{' '}
              <a href={`tel:+44${data.site.siteMetadata.phone.slice(1)}`}>
                {data.site.siteMetadata.phone}
              </a>
            </p>
            <p>
              Bookings require a non-refundable deposit of £200 to secure your
              place.
            </p>
          </Centre>
        </MaxWidthWrapper>
      </Row>
      <Row>
        <ImgContainer maxWidth={data.site.siteMetadata.maxWidth}>
          <ImgWrapper maxHeight='850px'>
            <Img fluid={data.doverRow.childImageSharp.fluid} />
          </ImgWrapper>
        </ImgContainer>
      </Row>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        tourPrice
        email
        phone
        maxWidth
      }
    }
    doverRow: file(relativePath: { eq: "dover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
