import styled from 'styled-components'

export const NavWrapper = styled.div`
  box-shadow: 0px 2px 5px 1px rgb(0.1, 0.1, 0.1, 0.5);
  overflow: auto;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--blue);
`

export const Nav = styled.nav`
   margin-left: auto;
   margin-right: auto;

   padding-left 2rem;
   padding-right 2rem;
   padding-top 0.1rem;
   padding-bottom 0.1rem;

   max-width: 1200px;

   display: grid;
   grid-template-columns: auto auto;

   align-items: center;
   justify-items: center;

   .active {
       display: block;
   }
   .notActive {
       display: none;
   }
`

export const Logo = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    a {
        display: grid;
        grid-template-columns: repeat(2, auto);
        text-decoration: none;
    }

    h1 {
        font-size 1.5rem;
        margin: 0;
        align-self: center;
        background: rgb(0, 0, 0);
        background: rgb(0, 0, 0, 0.6);
        padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    }

    .titleWide {
        display: block;
    }

   @media (max-width: 843px) {
       grid-row: 1;
   }
   @media (max-width: 800px) {
       justify-self: start;
   }
`

export const NavList = styled.div`
  ul {
    padding: 0;
    margin: 0;
    @media (max-width: 843px) {
      margin: 1rem 0 1rem 0;
    }
  }

  li {
    display: inline;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    font-size: 1.3rem;
    font-weight: 700;
    @media (max-width: 800px) {
      display: block;
    }
  }

  @media (max-width: 800px) {
    display: block;
    justify-self: start;
    line-height: 1.8rem;
  }
`

export const HamburgerIcon = styled.button`
  justify-self: end;
  display: none;
  @media (max-width: 800px) {
    display: inline-block;
    grid-row: 1;
  }
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  border: 3px solid var(--black);
  background-color: var(--secondary);
  svg {
    fill: var(--black);
  }
`
