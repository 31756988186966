import styled from 'styled-components'

export const PadDiv = styled.div`
  padding: 2rem 0 2rem 0;
`

export const Centre = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    max-width: 1000px;
    margin: 0 auto 1rem;
    padding: 0 20px 0 20px;
  }
`

export const ImgContainer = styled.div`
  max-width: ${props => props.maxWidth};
  margin: 0 auto;
`

export const ImgWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-height: ${props => props.maxHeight};
  img {
    object-fit: cover;
  }
`

export const MaxWidthWrapper = styled.div`
  margin: 0 auto;
  ${props => {
    return props.maxWidth
      ? `max-width: ${props.maxWidth}px;`
      : 'max-width: var(--maxWidth);'
  }}
  ${props => (props.noPad ? null : `padding: 1rem`)}
`
